import Vue from 'vue'
import VueRouter from 'vue-router'
// import $store from '@/store/'

Vue.use(VueRouter)

const ifAuthenticated = async (to, from, next) => {
  if (localStorage.getItem('user') && Vue.cookie.get('token')) {
    const user = JSON.parse(localStorage.getItem('user'))
    if (
      (to.name === 'createperson' && !user.access['contact.add'].value) ||
      (to.name === 'editperson' && !user.access['contact.edit'].value)
    ) {
      window.location.href = '/'
    } else {
      next()
    }
  } else {
    window.location.href = '/login'
  }
}
const ifUserEditor = async (to, from, next) => {
  if (localStorage.getItem('user') && Vue.cookie.get('token')) {
    const user = JSON.parse(localStorage.getItem('user'))
    if (
      (to.name === 'users' && !user.access['user.get'].value) ||
      (to.name === 'edituser' && !user.access['user.edit'].value) ||
      (to.name === 'newuser' && !user.access['user.add'].value)
    ) {
      window.location.href = '/'
    } else {
      next()
    }
  } else {
    window.location.href = '/login'
  }
}
const ifAdminStatistics = async (to, from, next) => {
  if (localStorage.getItem('user') && Vue.cookie.get('token')) {
    const user = JSON.parse(localStorage.getItem('user'))
    if (
      (to.name === 'user-statistics' || to.name === 'statistics') &&
      !user.access.admin.value
    ) {
      window.location.href = '/'
    } else {
      next()
    }
  } else {
    window.location.href = '/login'
  }
}

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: { layout: 'empty', title: 'Вход' },
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/password-recovery',
    name: 'passwordrecovery',
    meta: { layout: 'empty', title: 'Восстановление пароля' },
    component: () => import('../views/PasswordRecovery.vue'),
  },
  {
    path: '/recovery',
    name: 'recovery',
    meta: { layout: 'empty', title: 'Восстановление пароля' },
    component: () => import('../views/ChangePassword.vue'),
  },
  {
    path: '/',
    name: 'home',
    meta: { layout: 'main', title: 'Главная' },
    component: () => import('../views/Home.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/users',
    name: 'users',
    meta: { layout: 'main', title: 'Пользователи' },
    component: () => import('../views/Users.vue'),
    beforeEnter: ifUserEditor,
  },
  {
    path: '/edit-user/:id',
    name: 'edituser',
    meta: {
      layout: 'main',
      title: 'Редактирование пользователя',
    },
    component: () => import('../views/UsersEdit.vue'),
    beforeEnter: ifUserEditor,
  },
  {
    path: '/users/new',
    name: 'newuser',
    meta: {
      layout: 'main',
      title: 'Добавление нового пользователя',
    },
    component: () => import('../views/UsersEdit.vue'),
    beforeEnter: ifUserEditor,
  },
  {
    path: '/favorites',
    name: 'favorites',
    meta: { layout: 'main', title: 'Избранное' },
    component: () => import('../views/Favorites.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/history-watched',
    name: 'historywatched',
    meta: { layout: 'main', title: 'История просмотров' },
    component: () => import('../views/HistoryWatched.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/help',
    name: 'help',
    meta: { layout: 'main', title: 'Помощь' },
    component: () => import('../views/Help.vue'),
    // beforeEnter: ifAuthenticated,
  },
  {
    path: '/search',
    name: 'search',
    meta: { layout: 'main', title: 'Поиск контакта' },
    component: () => import('../views/Search.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/contacts/:id',
    name: 'showcontact',
    meta: { layout: 'main', title: 'Карточка контакта' },
    component: () => import('../views/ShowContact.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/create-person',
    name: 'createperson',
    meta: { layout: 'main', title: 'Создание контакта (персона)' },
    component: () => import('../views/CreatePerson.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/edit-person/:id',
    name: 'editperson',
    meta: {
      layout: 'main',
      title: 'Редактирование контакта (персона)',
    },
    component: () => import('../views/EditPerson.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/create-company',
    name: 'createcompany',
    meta: { layout: 'main', title: 'Создание контакта (юридическое лицо)' },
    component: () => import('../views/CompanyCreate.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/edit-company/:id',
    name: 'company',
    meta: {
      layout: 'main',
      title: 'Редактирование контакта (юридическое лицо)',
    },
    component: () => import('../views/CompanyEdit.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/user-statistics',
    name: 'user-statistics',
    meta: {
      layout: 'main',
      title: 'Статистика пользователей',
    },
    component: () => import('../views/UserStatistics.vue'),
    beforeEnter: ifAdminStatistics,
  },
  {
    path: '/statistics',
    name: 'statistics',
    meta: {
      layout: 'main',
      title: 'Статистика',
    },
    component: () => import('../views/Statistics.vue'),
    beforeEnter: ifAdminStatistics,
  },
  {
    path: '/contacts-statistics',
    name: 'contacts-statistics',
    meta: {
      layout: 'main',
      title: 'Статистика звонков',
    },
    component: () => import('../views/ContactsStatistics.vue'),
    beforeEnter: ifAdminStatistics,
  },
  {
    path: '/groups',
    name: 'groups',
    meta: {
      layout: 'main',
      title: 'Группы',
    },
    component: () => import('../views/Groups.vue'),
    beforeEnter: ifAdminStatistics,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
