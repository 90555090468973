<template>
  <el-container>
    <el-header :height="$store.state.ui.isMobile ? '40px' : '75px'">
      <div
        class="container-centered"
        :style="$store.state.ui.isMobile ? 'padding: 0' : 'padding: 0 5px'"
      >
        <Navbar :key="genKey" />
      </div>
    </el-header>
    <el-main>
      <router-view />
    </el-main>
  </el-container>
</template>

<script>
import Navbar from '@/components/app/Navbar'

export default {
  name: 'main-layout',
  components: {
    Navbar,
  },
  computed: {
    genKey() {
      return this.$store.getters.keyGen
    },
  },
}
</script>

<style lang="scss">
.el-header {
  border-bottom: 1px solid rgba(151, 151, 151, 0.18);
  padding: 0;
}
.el-main {
  padding: 0;
  padding-bottom: 55px;
}
</style>
