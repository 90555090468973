import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueCookie from 'vue-cookie'
import axios from 'axios'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/ru-RU'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/scss/main.scss'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import _ from 'lodash'

Vue.config.productionTip = false

Vue.use(ElementUI, { locale })
Vue.use(VueMeta)
Vue.use(VueCookie)

axios.interceptors.response.use(
  response => {
    try {
      if (response.data.code === 403 && window.location.pathname !== '/login') {
        alert(response.data.message)
        setTimeout(() => {
          window.location.href = '/login'
        }, 3000)
      }
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
  function (error) {
    return Promise.reject(error)
  },
)

Vue.prototype.$http = axios

new Vue({
  router,
  store,
  render: function (h) {
    return h(App)
  },
}).$mount('#app')
