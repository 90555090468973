<template>
  <div class="main-menu">
    <div
      v-if="!$store.state.ui.isMobile"
      class="navbar"
    >
      <el-menu
        :router="true"
        :default-active="activeLink"
        mode="horizontal"
      >
        <el-menu-item
          index="/"
          :route="{ name: 'home' }"
        >
          <router-link
            to="/"
            class="el-menu-item__link"
          >
            <span class="el-menu-item__title"></span>
          </router-link>
        </el-menu-item>
        <el-menu-item
          index="/favorites"
          :route="{ name: 'favorites' }"
          v-if="currentUser.access['user.favorite.get'].value"
        >
          <router-link
            to="/favorites"
            class="el-menu-item__link"
          >
            Избранное
          </router-link>
        </el-menu-item>
        <el-menu-item
          index="/history-watched"
          :route="{ name: 'historywatched' }"
        >
          <router-link
            to="/history-watched"
            class="el-menu-item__link"
          >
            История просмотров
          </router-link>
        </el-menu-item>
        <el-menu-item
          index="/help"
          :route="{ name: 'help' }"
        >
          <router-link
            to="/help"
            class="el-menu-item__link"
          >
            Справка
          </router-link>
        </el-menu-item>
      </el-menu>
      <el-dropdown
        trigger="click"
        @command="handleCommand"
      >
        <span class="el-dropdown-link">
          <ul>
            <li>{{ name }}</li>
            <li v-if="$cookie.get('model')">
              <div class="sub-title">{{ regions[$cookie.get('model')] }}</div>
            </li>
          </ul>
          <el-avatar
            v-if="!currentUser.image"
            :size="40"
          >
            <img src="../../assets/svg/icons/user.svg" />
          </el-avatar>
          <el-avatar
            v-else
            :size="40"
          >
            <img :src="this.$store.state.url_file + currentUser.image.path" />
          </el-avatar>
        </span>
        <el-dropdown-menu
          slot="dropdown"
          class="el-dropdown-menu__header"
        >
          <el-dropdown-item
            v-if="currentUser.access['user.get'].value"
            command="users"
            class="el-dropdown-menu__header-item"
            :class="{ active: activeLink === '/users' }"
            :disabled="activeLink === '/users'"
            icon="el-icon-user-plus"
          >
            Пользователи
          </el-dropdown-item>
          <el-dropdown-item
            v-if="currentUser.access['root'].value"
            command="groups"
            class="el-dropdown-menu__header-item"
            :class="{ active: activeLink === '/groups' }"
            :disabled="activeLink === '/groups'"
            icon="el-icon-user-plus"
          >
            Группы и права пользователей
          </el-dropdown-item>
          <el-dropdown-item
            v-if="currentUser.access.admin.value"
            command="user-statistics"
            class="el-dropdown-menu__header-item"
            :class="{ active: activeLink === '/user-statistics' }"
            :disabled="activeLink === '/user-statistics'"
            icon="el-icon-action"
          >
            Действия пользователей
          </el-dropdown-item>
          <el-dropdown-item
            v-if="currentUser.access.admin.value"
            command="contacts-statistics"
            class="el-dropdown-menu__header-item"
            :class="{ active: activeLink === '/contacts-statistics' }"
            :disabled="activeLink === '/contacts-statistics'"
            icon="el-icon-calls"
          >
            Статистика звонков
          </el-dropdown-item>
          <el-dropdown-item
            v-if="currentUser.access.admin.value"
            command="statistics"
            class="el-dropdown-menu__header-item"
            :class="{ active: activeLink === '/statistics' }"
            :disabled="activeLink === '/statistics'"
            icon="el-icon-statistic"
          >
            Статистика
          </el-dropdown-item>
          <el-dropdown-item
            command="logout"
            class="el-dropdown-menu__header-item"
            icon="el-icon-switch-button"
          >
            Выход
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div
      v-if="$store.state.ui.isMobile"
      class="navbar-mobile"
    >
      <el-menu
        class="navbar-mobile__menu"
        :router="true"
        :default-active="activeLink"
        mode="horizontal"
      >
        <el-menu-item
          index="/"
          :route="{ name: 'home' }"
        >
          <router-link
            to="/"
            class="el-menu-item__link"
          >
            <span class="el-menu-item__title"></span>
          </router-link>
        </el-menu-item>
      </el-menu>
      <div class="bugrer">
        <div
          :class="'bugrer__item' + (activeMenu ? ' active' : '')"
          @click="clickMenu"
        >
          <div class="bugrer__item__icon" />
        </div>
      </div>
    </div>
    <div :class="'mobile-menu' + (activeMenu ? ' active' : '')">
      <div class="mobile-menu__top">
        <el-menu
          class="navbar-mobile__menu"
          :router="true"
          :default-active="activeLink"
          mode="horizontal"
        >
          <el-menu-item
            index="/"
            :route="{ name: 'home' }"
            @click="closeMenu"
          >
            <router-link
              to="/"
              class="el-menu-item__link"
            >
              <span class="el-menu-item__title"></span>
            </router-link>
          </el-menu-item>
        </el-menu>
        <div class="bugrer">
          <div
            :class="'bugrer__item' + (activeMenu ? ' active' : '')"
            @click="clickMenu"
          >
            <div class="bugrer__item__icon" />
          </div>
        </div>
      </div>
      <div class="mobile-menu__body">
        <el-menu
          :router="true"
          :default-active="activeLink"
        >
          <el-menu-item
            style="padding-left: 30px"
            index="/favorites"
            :route="{ name: 'favorites' }"
            v-if="currentUser.access['user.favorite.get'].value"
            @click="closeMenu"
          >
            <router-link
              to="/favorites"
              class="el-menu-item__link"
            >
              Избранное
            </router-link>
          </el-menu-item>
          <el-menu-item
            style="padding-left: 30px"
            index="/history-watched"
            :route="{ name: 'historywatched' }"
            @click="closeMenu"
          >
            <router-link
              to="/history-watched"
              class="el-menu-item__link"
            >
              История просмотров
            </router-link>
          </el-menu-item>
          <el-menu-item
            style="padding-left: 30px"
            index="/help"
            :route="{ name: 'help' }"
            @click="closeMenu"
          >
            <router-link
              to="/help"
              class="el-menu-item__link"
            >
              Справка
            </router-link>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="mobile-menu__footer">
        <el-menu class="el-menu-vertical-demo">
          <el-submenu index="1">
            <template slot="title">
              <span class="el-dropdown-link">
                {{ name }}
              </span>
            </template>
            <el-menu-item
              v-if="currentUser.access['user.get'].value"
              @click="handleCommand('users')"
              class="el-dropdown-menu__header-item"
              :class="{ active: activeLink === '/users' }"
            >
              <span class="el-icon-user-plus"></span>
              <span>Пользователи</span>
            </el-menu-item>
            <el-menu-item
              v-if="currentUser.access['root'].value"
              @click="handleCommand('groups')"
              class="el-dropdown-menu__header-item"
              :class="{ active: activeLink === '/groups' }"
            >
              <span class="el-icon-user-plus"></span>
              <span>Группы и права пользователей</span>
            </el-menu-item>
            <el-menu-item
              v-if="currentUser.access.admin.value"
              @click="handleCommand('user-statistics')"
              class="el-dropdown-menu__header-item"
              :class="{ active: activeLink === '/user-statistics' }"
            >
              <span class="el-icon-action"></span>
              <span>Действия пользователей</span>
            </el-menu-item>
            <el-menu-item
              v-if="currentUser.access.admin.value"
              @click="handleCommand('contacts-statistics')"
              class="el-dropdown-menu__header-item"
              :class="{ active: activeLink === '/contacts-statistics' }"
            >
              <span class="el-icon-calls"></span>
              <span>Статистика звонков</span>
            </el-menu-item>
            <el-menu-item
              v-if="currentUser.access.admin.value"
              @click="handleCommand('statistics')"
              class="el-dropdown-menu__header-item"
              :class="{ active: activeLink === '/statistics' }"
            >
              <span class="el-icon-statistic"></span>
              <span>Статистика</span>
            </el-menu-item>
            <el-menu-item
              @click="handleCommand('logout')"
              index="1-1"
            >
              <span class="el-icon-exit-button"></span>
              <span>Выход</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: null,
      activeLink: null,
      activeMenu: null,
      regions: {
        _msk: 'Москва',
        _spb: 'Санкт-Петербург',
        _str: 'Стрингеры',
      },
      name: '',
    }
  },
  mounted() {
    this.activeLink = this.$route.path
    //let user = JSON.parse(localStorage.getItem('user'))
    this.name = `${this.currentUser.surname} ${this.currentUser.name}`
    this.name = this.name ? this.name.trim() : this.currentUser.email
  },
  computed: {
    currentUser() {
      return JSON.parse(localStorage.getItem('user'))
    },
  },
  watch: {
    $route(to, from) {
      this.activeLink = to.path
    },
  },
  methods: {
    closeMenu() {
      this.activeMenu = false
      this.$store.commit('setHideScroll', false)
    },
    clickMenu() {
      this.activeMenu = !this.activeMenu
      this.$store.commit('setHideScroll', this.activeMenu)
    },
    async handleCommand(command) {
      switch (command) {
        case 'logout':
          await this.$store.dispatch('logout')
          window.location.href = '/login'
          break
        case 'users':
          this.$router.push(`/users`)
          this.activeMenu = false
          this.$store.commit('setHideScroll', this.activeMenu)
          break
        case 'groups':
          this.$router.push(`/groups`)
          this.activeMenu = false
          this.$store.commit('setHideScroll', this.activeMenu)
          break
        case 'user-statistics':
          this.$router.push(`/user-statistics`)
          this.activeMenu = false
          this.$store.commit('setHideScroll', this.activeMenu)
          break
        case 'contacts-statistics':
          this.$router.push(`/contacts-statistics`)
          this.activeMenu = false
          this.$store.commit('setHideScroll', this.activeMenu)
          break
        case 'statistics':
          this.$router.push(`/statistics`)
          this.activeMenu = false
          this.$store.commit('setHideScroll', this.activeMenu)
          break
      }
    },
  },
}
</script>

<style lang="scss">
.navbar {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .el-menu {
    border-bottom: none;
  }

  & .el-menu-item {
    height: 74px;
    line-height: 74px;
    border-bottom: 3px solid transparent;
    padding: 0 18px;
    margin: 0 10px;
    color: initial;

    &:first-child {
      background: url('../../assets/svg/icons/home.svg');
      background-repeat: no-repeat;
      background-position: center;
      padding: 0 28px;
      margin: 0;
    }

    &.is-active,
    &:hover {
      border-bottom: 3px solid #7c43ba !important;
    }

    &__link {
      text-decoration: none;
    }

    &__title {
      font-size: 15px;
      color: #010101;
    }
  }

  & .el-dropdown-link {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #000;
    font-size: 15px;
    text-align: right;

    & .sub-title {
      text-align: center;
      padding: 2px;
      font-size: 12px;
      background-color: #f2ecf8;
      border-radius: 5px;
      color: #5c2d8e;
    }

    // &::after {
    //   content: '';
    //   display: inline-block;
    //   width: 40px;
    //   height: 40px;
    //   background: url('../../assets/svg/icons/user.svg');
    //   background-repeat: no-repeat;
    //   background-position: center;
    //   margin-left: 15px;
    // }

    & .el-avatar {
      margin-left: 15px;
      background-color: #fff;
    }
  }
}

.el-dropdown-menu__header {
  min-width: 174px;
  padding: 0;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 6px;
  overflow: hidden;

  & .popper__arrow {
    display: none;
  }
}

.el-dropdown-menu__header-item {
  display: flex;
  align-items: center;
  color: #010101;
  // border-radius: 6px;
  padding: 5px 20px;

  &.active {
    background-color: rgba(124, 67, 186, 0.1);
  }
  &.is-disabled {
    color: #010101;
  }

  & i {
    margin-right: 12px;
    max-height: 20px;
    min-width: 20px;
  }

  & .el-icon-switch-button::before {
    width: 20px;
    content: url('../../assets/svg/icons/logout.svg');
  }
  & .el-icon-action::before {
    content: url('../../assets/svg/icons/action.svg');
  }
  & .el-icon-calls::before {
    content: url('../../assets/svg/icons/calls.svg');
  }
  & .el-icon-statistic::before {
    content: url('../../assets/svg/icons/statistic.svg');
  }
  & .el-icon-user-plus::before {
    content: url('../../assets/svg/icons/iconuserpl.svg');
  }
}

.el-dropdown-menu__header-item:not(.is-disabled):hover,
.el-dropdown-menu__header-item:focus {
  background-color: rgb(49%, 26%, 73%, 0.1);
  color: #010101;
}

.navbar-mobile {
  display: flex;
  justify-content: space-between;
  &__menu {
    & .el-menu-item {
      height: 39px;
      &:first-child {
        background: url('../../assets/svg/icons/home-mobile.svg');
        background-repeat: no-repeat;
        background-position: center;
        padding: 18px;
        margin-left: 5px;
      }
    }
  }
}
.bugrer {
  &__item {
    width: 40px;
    height: 40px;
    background: #7c43ba;
    &.active {
      .bugrer__item__icon {
        background: url('../../assets/svg/icons/close.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    &__icon {
      height: 40px;
      line-height: 40px;
      color: #303133;
      padding: 0 20px;
      list-style: none;
      cursor: pointer;
      position: relative;
      transition: border-color 0.3s, background-color 0.3s, color 0.3s;
      box-sizing: border-box;
      white-space: nowrap;
      background: url('../../assets/svg/icons/bugrer.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
.mobile-menu {
  display: none;
  &.active {
    display: block;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: #fff;
    z-index: 1000;
  }
  &__top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(151, 151, 151, 0.18);
    height: 40px;
  }
  &__body {
    padding: 20px 0;
    .el-menu {
      .el-menu-item {
        padding: 0px 30px;
        height: 42px;
        line-height: 42px;
        &.is-active {
          outline: none;
          background-color: #f2ecf8;
        }
        & a {
          text-decoration: none;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #010101;
        }
      }
    }
  }
  &__footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    & .el-menu-vertical-demo {
      border-right: 0px;
      .el-submenu {
        &.is-opened {
          .el-submenu__title {
            border-top: 0px;
            border-bottom: 1px solid rgba(151, 151, 151, 0.18);
            &:hover {
              background-color: #fff;
            }
          }
        }
        .el-submenu__title {
          border-top: 1px solid rgba(151, 151, 151, 0.18);
          padding: 15px 20px;
          margin: 0px;
          display: flex;
          height: 72px;
        }
        .el-menu {
          margin: 0px -15px;
          .el-menu-item {
            .active {
              outline: none;
              background-color: #f2ecf8;
            }
            .el-icon-exit-button {
              width: 16px;
              margin-right: 12px;
              content: url('../../assets/svg/icons/logout.svg');
            }
            .el-icon-action {
              width: 23px;
              content: url('../../assets/svg/icons/action.svg');
            }
            .el-icon-statistic {
              width: 23px;
              content: url('../../assets/svg/icons/statistic.svg');
            }
            .el-icon-calls {
              width: 23px;
              content: url('../../assets/svg/icons/calls.svg');
            }
          }
        }
      }
    }
    & .el-dropdown-link {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #000;
      font-size: 15px;
      text-align: right;

      &::before {
        content: '';
        display: inline-block;
        width: 40px;
        height: 40px;
        background: url('../../assets/svg/icons/user.svg');
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 15px;
      }
    }
  }
}
</style>
