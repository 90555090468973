<template>
  <div
    id="app"
    class="app"
    :class="{ 'hide-scroll': this.$store.state.ui.hideScroll }"
  >
    <el-backtop target=".app"></el-backtop>
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import EmptyLayout from '@/layouts/EmptyLayout'
import MainLayout from '@/layouts/MainLayout'

export default {
  components: {
    EmptyLayout,
    MainLayout,
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || 'empty') + '-layout'
    },
    currentUser() {
      return JSON.parse(localStorage.getItem('user'))
    },
  },
  beforeMount() {
    ;(async () => {
      if (!this.$store.state.contacts.favoritesPreLoads) {
        await this.getFavoritesContacts()
      }
      if (!this.$store.state.tags.tagsListPreLoads) {
        await this.getTagsList()
      }
    })()
  },
  mounted() {
    window.addEventListener('resize', this.resizeListener)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener)
  },
  watch: {
    '$route.query.model': {
      immediate: true,
      deep: true,
      async handler() {
        if (this.$route.query.model) {
          if (this.$cookie.get('model')) {
            let path = this.$route.path.split('/')
            if (
              this.$cookie.get('model') != this.$route.query.model &&
              !path.includes('recovery')
            ) {
              alert(
                'Введенная ссылка ведёт на карточку которая относится к другому региону и вам не доступна. Если у вас есть доступы к другому региону, введите связанный с ним логин и пароль, и попробуйте еще раз перейти по этой ссылке.',
              )
              this.$cookie.set('model', this.$route.query.model)
              this.$router.replace(
                {
                  query: Object.assign(
                    { ...this.$route.query },
                    { model: this.$cookie.get('model') },
                  ),
                },
                () => {},
              )
            }
          } else {
            let path = this.$route.path.split('/')
            if (path.includes('login') || path.includes('recovery')) {
              this.$cookie.set('model', this.$route.query.model)
            } else {
              alert(
                'Вы не авторизованы в системе! Перенаправление на авторизацию.',
              )
              this.$cookie.set('model', this.$route.query.model)

              this.$router.push('/login')
            }
          }
        }
      },
    },
  },
  methods: {
    resizeListener(event) {
      /* Pad size */
      console.log('test')
      if (
        event.currentTarget.innerWidth <= 1152 &&
        event.currentTarget.innerWidth > 768
      ) {
        this.$store.commit('setPadMode', true)
      } else {
        this.$store.commit('setPadMode', false)
      }
      /* Mobile size */
      if (event.currentTarget.innerWidth <= 768) {
        this.$store.commit('setMobileMode', true)
      } else {
        this.$store.commit('setMobileMode', false)
      }
    },
    async getFavoritesContacts() {
      if (
        this.currentUser &&
        this.currentUser.access['user.favorite.get'].value
      ) {
        try {
          return await this.$store
            .dispatch('fetchFavorites', {})
            .then(response => {
              if (response.code === 200) {
                const favorites = response.data || []
                this.$store.commit('setFavoritesPreLoads', true)
                this.$store.commit('setFavorites', favorites)
              }
            })
        } catch (e) {
          console.log(e)
        }
      }
    },
    async getTagsList() {
      try {
        return await this.$store.dispatch('fetchTagsList').then(response => {
          if (response.code === 200) {
            const tags = response.data || []
            this.$store.commit('setTagsListPreLoads', true)
            this.$store.commit('setTagsList', tags)
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
