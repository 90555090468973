import Vue from 'vue'
import axios from 'axios'

export default {
  actions: {
    async uploadFile({ commit, dispatch }, data) {
      try {
        data.append('model', Vue.cookie.get('model'))
        data.append('token', Vue.cookie.get('token'))
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/file/upload`,
          data: data,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async getFile({ commit, dispatch }, data) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/file/get`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async deleteFile({ commit, dispatch }, data) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/file/delete`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async export({ commit, dispatch }, data) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/file/exports`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
  },
}
