import Vue from 'vue'
import axios from 'axios'

export default {
  actions: {
    async fetchUserById({ commit, dispatch }, data) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/user/get`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async changePassword({ commit, dispatch }, data) {
      try {
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/user/edit`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async usersGetList({ commit, dispatch }, data) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/user/getList`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async userAdd({ commit, dispatch }, data) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/user/add`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async userEdit({ commit, dispatch }, data) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/user/edit`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async userDelete({ commit, dispatch }, data) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/user/delete`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
  },
}
