export default {
  state: {
    isMobile: window.innerWidth < 768,
    isPad: window.innerWidth < 1152,
    hideScroll: false,
  },
  mutations: {
    setMobileMode(state, payload) {
      state.isMobile = payload
    },
    setPadMode(state, payload) {
      state.isPad = payload
    },
    setHideScroll(state, payload) {
      state.hideScroll = payload
    },
  },
}
