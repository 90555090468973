export default {
  state: {
    searchField: null,
  },
  mutations: {
    setSearchFiled(state, payload) {
      state.searchField = payload
    },
  },
}
