import Vue from 'vue'
import axios from 'axios'

export default {
  actions: {
    async getHistoryListCurrentUser({ commit, dispatch }, data = {}) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        data.name = 'contact.get'
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/log/getList`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async getLogs({ commit, dispatch }, data = {}) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/log/getList`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async changes({ commit, dispatch }, data = {}) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/log/changes`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
  },
}
