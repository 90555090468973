import Vue from 'vue'
import axios from 'axios'

export default {
  actions: {
    async groupsGetList({ commit, dispatch }, data) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/group/getList`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async groupsAccessList({ commit, dispatch }, data) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/group/access/getList`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async addGroup({ commit, dispatch }, data) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/group/add`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async editGroup({ commit, dispatch }, data) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/group/edit`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async deleteGroup({ commit, dispatch }, data) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/group/delete`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async addGroupAccess({ commit, dispatch }, data) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/group/access/add`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async deleteGroupAccess({ commit, dispatch }, data) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/group/access/delete`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
  },
}
