import Vue from 'vue'
import axios from 'axios'

export default {
  actions: {
    async createField({ commit, dispatch }, data) {
      try {
        data.token = Vue.cookie.get('token')
        data.model = Vue.cookie.get('model')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/contact/field/add`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async editField({ commit, dispatch }, data) {
      try {
        data.token = Vue.cookie.get('token')
        data.model = Vue.cookie.get('model')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/contact/field/edit`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async deleteField({ commit, dispatch }, data) {
      try {
        data.token = Vue.cookie.get('token')
        data.model = Vue.cookie.get('model')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/contact/field/delete`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async getFieldsList({ commit, dispatch }, data) {
      try {
        data.token = Vue.cookie.get('token')
        data.model = Vue.cookie.get('model')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/contact/field/getList`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
  },
}
