import Vue from 'vue'
import axios from 'axios'

export default {
  state: {
    favorites: [],
    favoritesPreLoads: false,
  },
  mutations: {
    setFavorites(state, payload) {
      state.favorites = payload
    },
    setFavoritesPreLoads(state, payload) {
      state.favoritesPreLoads = payload
    },
  },
  actions: {
    async fetchContacts({ commit, dispatch }, data = {}) {
      try {
        data.token = Vue.cookie.get('token')
        data.model = Vue.cookie.get('model')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/contact/getList`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async fetchContactById({ commit, dispatch }, data) {
      try {
        data.token = Vue.cookie.get('token')
        data.model = Vue.cookie.get('model')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/contact/get`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async createContact({ commit, dispatch }, data) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/contact/add`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async editContact({ commit, dispatch }, data) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/contact/edit`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async deleteContact({ commit, dispatch }, data) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/contact/delete`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async fetchFavorites({ commit, dispatch }, data = {}) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        data.limit = 1000
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/user/favorite/getList`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async addFavorite({ commit, dispatch }, data) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/user/favorite/add`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async delFavorite({ commit, dispatch }, data) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/user/favorite/delete`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async sendMail({ commit, dispatch }, data = {}) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/user/message/add`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async fetchStatistic({ commit, dispatch }, data = {}) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/statistic/get`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async setStatistic({ commit, dispatch }, data = {}) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/statistic/set`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async delStatistic({ commit, dispatch }, data = {}) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/statistic/delete`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async fetchStatisticList({ commit, dispatch }, data = {}) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/statistic/getStatisticList`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
    async fetchStatisticById({ commit, dispatch }, data = {}) {
      try {
        data.model = Vue.cookie.get('model')
        data.token = Vue.cookie.get('token')
        const response = await axios({
          method: 'post',
          url: `${this.state.url}/1/statistic/getStatistic`,
          data: JSON.stringify(data),
        })
        return response.data
      } catch (e) {
        throw e
      }
    },
  },
}
