import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import users from './users'
import contacts from './contacts'
import search from './search'
import files from './files'
import fields from './fields'
import logs from './logs'
import ui from './ui'
import tags from './tags'
import group from './group'
import access from './access'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    url: process.env.VUE_APP_URL,
    url_file: process.env.VUE_APP_URL_FILE,
    key: 1,
  },
  mutations: {
    generateKey(state) {
      state.key = Math.random()
    },
  },
  actions: {},
  getters: {
    keyGen: state => {
      return state.key
    },
  },
  modules: {
    auth,
    users,
    contacts,
    search,
    files,
    fields,
    logs,
    ui,
    tags,
    group,
    access,
  },
})
